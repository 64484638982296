import gql from 'graphql-tag';

import { CurrentEvent } from './__generated__/CurrentEvent';

export const CURRENT_EVENT_QUERY = gql`
  query CurrentEvent {
    currentEvent {
      id
      startDate
      endDate
      year
      registrationStatus
      registrationStartDate
      registrationEndDate
      surveysEnabled
      surveyEndDate
      refundsEnabled
      refundEndDate
      conferenceAndFestivalPassPrice
      festivalPassPrice
      subsidizedPassesAvailable
    }
  }
`;

export type { CurrentEvent };
